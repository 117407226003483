<template>
    <breadcrumb btnText="新建权限" @btn-click="$router.push('permissions/create')"></breadcrumb>

    <div class="permissions-page">
        <table class="table">
            <thead>
                <td width="60">id</td>
                <td width="220">name</td>
                <td width="220">display</td>
                <td width="160">守卫</td>
                <td width="220">创建时间</td>
                <td width="140">操作</td>
            </thead>
            <tbody>
                <tr v-for="(item, index) in list" :key="index">
                    <td>{{ item.id }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.display }}</td>
                    <td>{{ item.guard_name }}</td>
                    <td>{{ item.created_at }}</td>
                    <td>
                        <div class="operation">
                            <n-button class="btn" 
                                type="primary" 
                                @click="$router.push(`permissions/edit/${item.id}`)"
                            >
                                <edit theme="outline" size="16" fill="#fff"/>
                            </n-button>
                            <n-popconfirm 
                                position="bottom right"
                                content="此操作将影响接口调用，请谨慎操作，确定永久删除该权限吗？"
                                @confirm="handlerDelete(item.id, index)"
                            >
                                <n-button class="btn" type="danger">
                                    <delete theme="outline" size="16" fill="#fff"/>
                                </n-button>
                            </n-popconfirm>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <no-data v-if="list.length === 0"></no-data>
    </div>

    <n-notification 
        v-model:show="notification.show"
        :content="notification.content"
    >
        <template v-slot:icon>
            <attention v-if="notification.state === 'error'" theme="outline" size="24" fill="#ff2121"/>
            <check-one v-else theme="outline" size="24" fill="#00c12b"/>
        </template>
    </n-notification>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import NoData from '@/components/layout/NoData.vue';
import { Edit, Delete, Attention, CheckOne } from '@icon-park/vue-next';


@Options({
    components: {
        Breadcrumb, NoData,
        Edit, Delete, Attention, CheckOne
    },
    data(){
        return{
            list: [],
            notification: {
                show: false,
                content: '',
                state: 'error'
            }
        }
    },
    mounted(){
        this.getPermissionsList();
    },
    methods: {
        getPermissionsList(){
            this.$api.getPermissions().then((res: any) => {
                this.list = res.data;
            })
        },
        handlerDelete(id: number, index: number){
            this.$api.destroyPermission(id).then((res: any) => {
                if(res.msg === 'success') {
                    this.list.splice(index, 1);
                    this.notification = {
                        show: true,
                        content: '权限删除成功',
                        state: 'success'
                    }
                } else {
                    this.notification = {
                        show: true,
                        content: res.msg,
                        state: 'error'
                    }
                }
            })
        }
    }
})

export default class Permissions extends Vue {}
</script>

<style lang="scss" scoped>
.permissions-page{
    @extend .flex-column;
    margin: 10px;
    padding: 10px;
    border-radius: $radius;
    background-color: $white;
    box-shadow: $shadow-default;
}
</style>